import "../stylesheets/application.scss";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "../components/Dashboard";

document.addEventListener("DOMContentLoaded", () => {
  const rootElement = document.getElementById("root");
  if (rootElement) {
    const root = createRoot(rootElement);
    root.render(
      <Router>
        <Routes>
          <Route path="/v3/dashboards" element={<Dashboard />} />
        </Routes>
      </Router>
    );
  }
});
import React from 'react';

const Dashboard = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-yellow-400 via-red-500 to-pink-500">
      <div className="bg-white bg-opacity-90 text-gray-800 p-10 border-4 border-yellow-600 rounded-xl shadow-lg text-center max-w-2xl mx-auto">
        <div className="text-6xl text-yellow-600 animate-bounce mb-4">🚧</div>
        <h1 className="text-4xl font-extrabold mb-4">In Progress</h1>
        <p className="text-xl mb-6">This view is currently under construction. Please check back later.</p>
        <button className="bg-purple-600 text-white px-6 py-2 rounded-full font-semibold hover:bg-purple-700 transition duration-300" onClick={() => window.history.back()}>
          Go Back
        </button>
      </div>
    </div>
  );
};

export default Dashboard;